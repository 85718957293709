<template>
<div>
    <v-dialog v-model="$store.state.dialog" :fullscreen="isfullscreen" width="70%" transition="dialog-top-transition">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title>{{ $store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل' }}
                    {{ this.$route.meta.single }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="isfullscreen = !isfullscreen">
                    <v-icon small>{{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}</v-icon>
                </v-btn>
                <v-btn @click="close()" icon> <v-icon>close</v-icon> </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            <v-row>

                                <!-- clientId -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2">
                                        الزبون
                                        <span class="required">*</span>
                                    </h4>
                                    <v-autocomplete :disabled="this.isEdit" v-model="item.fromUserId" :items="clients"
                                        :loading="$global.state.loading" :rules="[$global.state.required()]"
                                        item-text="fullName" item-value="id" color="info" placeholder="الزبون"
                                        no-data-text="لا توجد بيانات" hide-details="auto"
                                        prepend-inner-icon="fi fi-br-users" outlined filled />

                                </v-col>

                                <!-- orderName -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2">
                                        اسم الطلبية
                                        <span class="required">*</span>
                                    </h4>
                                    <v-text-field v-model="item.orderName" placeholder="طلبية صيدلية الكوثر"
                                        :rules="[$global.state.required()]" required color="info" filled outlined
                                        hide-details="auto" />
                                </v-col>

                                <!-- deliveryAddress -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2">
                                        عنوان التوصيل
                                        <span class="required">*</span>
                                    </h4>
                                    <v-text-field v-model="item.deliveryAddress" placeholder="النجف الاشرف"
                                        :rules="[$global.state.required()]" required color="info" filled outlined
                                        hide-details="auto" />
                                </v-col>

                                <!-- recipientPhoneNo1 -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2">
                                        رقم هاتف المستلم 1
                                        <span class="required">*</span>
                                    </h4>
                                    <v-text-field v-model="item.recipientPhoneNo1" v-mask="$phoneMask" dir="ltr"
                                        color="info" placeholder="+964 ### ### ####" :rules="[$global.state.required()]"
                                        required filled outlined hide-details="auto" />
                                </v-col>

                                <!-- recipientPhoneNo2 -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2">
                                        رقم هاتف المستلم 2
                                    </h4>
                                    <v-text-field v-model="item.recipientPhoneNo2" v-mask="$phoneMask" dir="ltr"
                                        color="info" placeholder="+964 ### ### ####" filled outlined
                                        hide-details="auto" />
                                </v-col>

                                <!-- imageUrl -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2">صورة</h4>
                                    <FileUpload accept="image/*;capture=camera" formData="files"
                                        endPoint="UploadFile/postUploadFiles" buttonText="رفع صورة" icon="true" />
                                </v-col>


                            </v-row>


                            <v-divider class="mt-5 my-2" />



                            <!-- products -->
                            <v-card flat class="grey lighten-4 pa-2 mt-5" v-for="(product, index) in carts"
                                :key="index">
                                <v-btn class="btn-remove-productSize" @click="remove(index)" color="error" icon>
                                    <v-icon>remove_shopping_cart</v-icon>
                                </v-btn>
                                <v-row>

                                    <!-- categoryId  -->
                                    <v-col cols="12" md="4">
                                        <h4 class="mb-2">القسم</h4>
                                        <v-autocomplete clearable v-model="product.categoryId"
                                            @input="getProducts($event, index, false)" item-text="name" item-value="id"
                                            :items="$global.state.listCategories" placeholder="القسم" color="info"
                                            :loading="$global.state.loading" no-data-text="لا توجد بيانات"
                                            hide-details="auto" outlined required filled />
                                    </v-col>

                                    <!-- productId  -->
                                    <v-col cols="12" md="4">
                                        <h4 class="mb-2">المنتج</h4>
                                        <v-autocomplete clearable outlined :items="$global.state.products[index]"
                                            v-model="selectedproduct[index]" filled return-object hide-details="auto"
                                            placeholder="المنتج" color="info">
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs" :input-value="data.selected"
                                                    @click="data.select">
                                                    <v-avatar left>
                                                        <v-img :src="data.item.mainImageUrl"></v-img>
                                                    </v-avatar>
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="typeof data.item !== 'object'
                                                ">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-avatar color="primary">
                                                        <img :src="data.item.mainImageUrl" />
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            v-html="data.item.description"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>

                                    <!-- productSizeId  -->
                                    <v-col cols="12" md="4">
                                        <h4 class="mb-2">القياس</h4>
                                        <v-autocomplete clearable outlined :items="selectedproduct[index].sizes"
                                            v-model="product.productSizeId" @input="setProductSize($event, index)"
                                            filled return-object hide-details="auto" placeholder="قياس 15 * 20"
                                            color="info">
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs" :input-value="data.selected"
                                                    @click="data.select">
                                                    <v-avatar left>
                                                        <v-img :src="selectedproduct[index].mainImageUrl"></v-img>
                                                    </v-avatar>
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="typeof data.item !== 'object'
                                                ">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-avatar color="primary">
                                                        <img :src="selectedproduct[index].mainImageUrl" />
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle> {{ data.item.price | formatCurrency
                                                            }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>

                                    <!-- qty -->
                                    <v-col cols="12" md="4">
                                        <h4 class="mb-2">الكمية
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field type="number" :rules="[$global.state.required()]"
                                            v-model="product.qty" placeholder="الكمية" min="1" filled outlined
                                            hide-details="auto" />
                                    </v-col>

                                    <!-- price -->
                                    <v-col cols="12" md="4">
                                        <h4 class="mb-2">السعر
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field v-model="product.price" :rules="[$global.state.required()]"
                                            placeholder="0.0" suffix="IQD" filled outlined hide-details="auto"
                                            required />
                                    </v-col>

                                    <!-- discount -->
                                    <v-col cols="12" md="4">
                                        <h4 class="mb-2">
                                            الخصم ٪
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field v-model="product.discount"
                                            :rules="[$global.state.maxNumber(100), $global.state.minNumber(0)]"
                                            suffix="%" type="number" :min="0" :max="100" outlined filled color="info"
                                            hide-details="auto" required></v-text-field>
                                    </v-col>

                                </v-row>
                            </v-card>
                            <v-btn color="primary secondary--text" @click="addProduct()">
                                <h3>إضافة قياس</h3>
                            </v-btn>
                            <!-- note a-->
                            <v-col cols="12" md="12">
                                <h4 class="mb-2">
                                    ملاحظات
                                </h4>
                                <v-text-field v-model="item.note" placeholder="ملاحظات" color="info" filled outlined
                                    hide-details="auto" />
                            </v-col>

                        </v-container>
                    </v-card>
                    <!-- actions -->
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :loading="$global.state.loading" x-large color="primary"
                                class="btn secondary--text" :disabled="!valid">
                                حفظ
                            </v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined> إلغاء </v-btn>
                        </v-row>
                    </v-col>

                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    components: {
        FileUpload: () => import("@/components/FileUpload.vue"),

    },

    computed: {
        ...mapState(["previews"]),
    },

    data() {
        return {
            isEdit: false,
            valid: false,
            isfullscreen: false,
            item: {},
            selectedproduct: [{
                sizes: [],
            }],
            selectedproductSize: "",
            clients: [],
            carts: [],
            visible: this.$store.state.dialog,
            products: []
        };
    },
    watch: {
        '$store.state.dialog': function (newVal, oldVal) {
            if (newVal) {
                this.setFields();

            }
        }

    },
    mounted() {

        this.getListCategories();

        this.getClients();
        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
            this.item.categoryId = this.$store.state.itemDetails.category.id;
        });
    },

    methods: {
        setFields() {
            this.reset();
            if (this.$store.state.itemDetails.dialogType === "Edit") {
                this.isEdit = true;
                const order = this.$store.state.itemDetails.order;
                this.previews.push(order.imageUrl);

                this.item.id = order.id;
                this.item.orderName = order.orderName;
                this.item.orderNo = order.orderNo;
                this.item.deliveryAddress = order.deliveryAddress;
                this.item.recipientPhoneNo1 = order.recipientPhoneNo1;
                this.item.recipientPhoneNo2 = order.recipientPhoneNo2;
                this.item.note = order.note;
                this.item.imageUrl = order.imageUrl;
                this.item.fromUserId = order.fromUserId;
                for (let index = 0; index < order.carts.length; index++) {
                    this.addProduct();
                    const item = order.carts[index];

                    this.getProducts(item.categoryId, index);


                }


            }
            else {
                this.isEdit = false;
            }
        },

        getProducts(categoryId, index, forceChange = true) {


            this.$global.state.categoryId = categoryId
            this.$global.dispatch(`getProducts`, {
                categoryId: categoryId,
                index: index
            }).then(result => {

                if (this.isEdit && forceChange) {
                    const order = this.$store.state.itemDetails.order;
                    const selectedProd = result.find(x => x.id == order.carts[index].productId)
                    this.selectedproduct[index] = selectedProd;

                    const selectedSize = selectedProd.sizes.find(p => p.id == order.carts[index].productSizeId)
                    this.carts[index].categoryId = order.carts[index].categoryId;
                    this.carts[index].productId = order.carts[index].productId;
                    this.carts[index].qty = order.carts[index].qty;
                    this.carts[index].discount = order.carts[index].discount.toString();
                    this.carts[index].productSizeId = selectedSize;
                    this.carts[index].price = this.$genericService.formatCurrency(order.carts[index].price);
                }



            });

        },


        reset() {
            this.item = {
                id: 0,
                orderName: "",
                deliveryAddress: "",
                recipientPhoneNo1: "",
                recipientPhoneNo2: "",
                note: "",
                imageUrl: "",
                fromOffers: false,
                carts: [

                    {

                        productSizeId: "",
                        categoryId: "",
                        productId: "",
                        qty: 1,
                        price: 0,
                        discount: 0,
                    },
                ],
            };

            this.$store.commit("clearFiles");
        },

        submit() {


            this.$refs.form.validate()
            if (this.$refs.form.validate()) {
                //this.item.carts[0].price = typeof this.item.carts[0].price == "string" ? parseFloat(this.item.carts[0].price.replace(/,/g, "")) : this.item.carts[0].price;
                this.item.carts = this.carts.map(x => {
                    return {
                        productSizeId: x.productSizeId.id,
                        categoryId: x.categoryId,
                        productId: x.productId,
                        qty: x.qty,
                        price: typeof x.price == "string" ? parseFloat(x.price.replace(/,/g, "")) : x.price,
                        discount: x.discount,
                    }
                })

                if (!this.isEdit) {
                    this.addItem();
                } else {
                    this.editItem();
                }
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            if (this.$store.state.files[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        this.item.imageUrl = res.data.result.fileList[0].path;
                    });
            }
            await this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `تم انشاء الطلب بنجاح`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async editItem() {
            this.$global.state.loading = true;
            if (this.$store.state.files[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        this.item.imageUrl = res.data.result.fileList[0].path;
                    });
            }
            this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", { message: `${res.data.message}`, type: "success" });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error" });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        setProductSize(selectedSize, index) {
            this.carts[index].price = this.$genericService.formatCurrency(selectedSize.price);
            this.carts[index].qty = selectedSize.minNoSale;
            this.carts[index].discount = selectedSize.discount.toString();




        },

        getListCategories() {
            this.$global.dispatch(`getListCategories`);
        },

        async getClients() {
            try {
                this.$global.state.loading = true;
                const res = await this.$http.get(`Clients/list`)
                this.clients = res.data.result;
            } catch (err) {
                // log//
            } finally {
                this.$global.state.loading = false;
            }
        },

        addProduct() {
            this.carts.push({
                productSizeId: {},
                categoryId: "",
                productId: "",
                qty: 1,
                price: 0,
                discount: "0",
            })
            this.selectedproduct.push({
                sizes: [],
            })
        },

        remove(index) {
            this.selectedproduct.splice(index, 1);
            this.carts.splice(index, 1);

            this.$global.state.products.splice(index, 1);

        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
            this.carts = []
            this.selectedproduct = [];
            this.isEdit = false;
            this.selectedProductSize = "";
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>


<style scoped>
.btn-remove-productSize {
    position: absolute;
    top: -15px;
    left: -15px;
}
</style>
